import { delay, call, spawn, put, race, select, take, takeLatest, takeEvery } from 'redux-saga/effects';
import { isNil, toLower } from 'ramda';
import { DESKTOP, MOBILE } from 'mangools-commons/lib/constants/Platforms';
import ErrorCodes, {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/lib/constants/ErrorCodes';

import { handleUncaught, logError, handleError } from 'sagas/errorSagas';

import { trackingSelector } from 'selectors/dataSelectors';
import { accessTokenSelector } from 'selectors/userSelectors';

import { showInfoNotification } from 'sagas/uiSagas';

import {
    showDeleteConfirmationMessage,
    showFailureMessage,
    showTrackingAlreadyExistsMessage,
    showAccessDeniedMessage,
    showUnauthorizedMessage,
    showPricingMessage,
} from 'actions/uiActions';

import {
    fetchingTrackingsDeleteAction,
    receivedTrackingsDeleteAction,
    errorTrackingsDeleteAction,
    cancelledTrackingsDeleteAction,
    fetchingTrackingsRestoreAction,
    receivedTrackingsRestoreAction,
    errorTrackingsRestoreAction,
    cancelledTrackingsRestoreAction,
} from 'actions/dataActions';

import { requestedNavigationAction } from 'actions/routerActions';

import TrackingSource from 'sources/TrackingSource';

import RoutePaths from 'constants/RoutePaths';
import ActionTypes from 'constants/ActionTypes';
import DeleteResourceTypes from 'constants/DeleteResourceTypes';
import Strings from 'constants/Strings';
import { isWithDeletedTrackingsSelector } from 'selectors/uiSelectors';
import { ErrorTypes } from 'constants/ErrorTypes';

const MAX_REQUEST_TIMEOUT = 60 * 1000

function getTrackingPlatformLabel(tracking) {
    const trackingPlatformLabel = tracking.platformId === DESKTOP.id ? DESKTOP.label : MOBILE.label;
    return toLower(trackingPlatformLabel);
}

const deleteTracking = handleUncaught(
    function* deleteTracking(action, retrying = false) {
        const { id, url } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const tracking = yield select(trackingSelector, id);

        const trackingPlatformLabel = getTrackingPlatformLabel(tracking);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(
                showDeleteConfirmationMessage({
                    resourceName: `${url} ${trackingPlatformLabel}`,
                    resourceType: DeleteResourceTypes.TRACKING,
                    details: {
                        location: tracking.location,
                        platform: trackingPlatformLabel,
                        domain: url,
                    },
                }),
            );

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(requestedNavigationAction(RoutePaths.TRACKINGS, {}));
            yield put(fetchingTrackingsDeleteAction());

            const { result, _timeout } = yield race({
                result: call(TrackingSource.delete, accessToken, id),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const isWithDeleted = yield select(isWithDeletedTrackingsSelector);
                    yield put(receivedTrackingsDeleteAction({ id, isWithDeleted }));
                    yield call(showInfoNotification, `Tracking <strong>${url}</strong> was successfully deleted.`, {
                        html: true,
                    });
                } else {
                    const handler = handleError(
                        action,
                        payload,
                        'UpdateTrackingDomainSaga',
                        retrying,
                        errorTrackingsDeleteAction,
                        null,
                        deleteTracking,
                        Strings.messages.failure.delete_tracking_error,
                    );

                    yield call(handler);
                }
            } else {
                yield put(errorTrackingsDeleteAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.delete_tracking_error }));
                yield call(logError, 'DeleteTrackingSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledTrackingsDeleteAction(id));
        }
    },
    function* onError() {
        yield put(errorTrackingsDeleteAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_tracking_error }));
    },
);

const restoreTracking = handleUncaught(
    function* restoreTracking(action, retrying = false) {
        const { id } = action.payload;
        const accessToken = yield select(accessTokenSelector);

        const confirm = true;

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            // yield put(requestedNavigationAction(RoutePaths.TRACKINGS, {}));
            yield put(fetchingTrackingsRestoreAction());

            const { result, _timeout } = yield race({
                result: call(TrackingSource.restore, accessToken, id),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedTrackingsRestoreAction(payload));
                    yield call(showInfoNotification, `Tracking was successfully restored.`, {
                        html: true,
                    });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.CONFLICT: {
                            yield put(errorTrackingsRestoreAction(payload));
                            // Show tracking already exists
                            yield put(
                                showTrackingAlreadyExistsMessage({
                                    trackingId: id,
                                    keywords: undefined,
                                }),
                            );

                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorTrackingsRestoreAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorTrackingsRestoreAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.UNPROCESSABLE_ENTITY: {
                            yield put(errorTrackingsRestoreAction(payload));
                            yield put(showPricingMessage()); // Not enough remaining tracked keywords
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorTrackingsRestoreAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorTrackingsRestoreAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'RestoreTrackingDataSaga', payload);
                            } else {
                                yield call(restoreTracking, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorTrackingsRestoreAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.create_tracking_error,
                                    }),
                                );
                                yield call(logError, 'RestoreTrackingDataSaga', payload);
                            } else {
                                const handler = handleError(
                                    action,
                                    payload,
                                    'RestoreTrackingDomainSaga',
                                    retrying,
                                    errorTrackingsRestoreAction,
                                    null,
                                    restoreTracking,
                                    Strings.messages.failure.restore_tracking_error,
                                );

                                yield call(handler);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingsRestoreAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.restore_tracking_error }));
                yield call(logError, 'RestoreTrackingSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledTrackingsRestoreAction(id));
        }
    },
    function* onError() {
        yield put(errorTrackingsRestoreAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.restore_tracking_error }));
    },
);

function* handleGMBDataError(payload) {
    yield put(showFailureMessage({ details: Strings.messages.failure.fetch_gmb_data_error }));
    yield call(logError, 'FetchGMBSaga', payload);
}

function* watchGMBErrorEvents() {
    yield takeEvery(ActionTypes.UI_GMB_DATA_ERROR, handleGMBDataError);
}

function* watchDeleteTrackingRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKINGS_DELETE_REQUESTED, deleteTracking);
}

function* watchRestoreTrackingRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKINGS_RESTORE_REQUESTED, restoreTracking);
}

export function* watchTrackingRequests() {
    yield spawn(watchGMBErrorEvents);
    yield spawn(watchDeleteTrackingRequests);
    yield spawn(watchRestoreTrackingRequests);
}
