import { eventChannel } from 'redux-saga';
import { fork, delay, call, spawn, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { isNil, concat, slice, includes } from 'ramda';
import FileService from 'mangools-commons/lib/services/FileService';
import DownloaderService from 'mangools-commons/lib/services/DownloaderService';
import PlatformFinderService from 'mangools-commons/lib/services/PlatformFinderService';
import { DESKTOP, MOBILE } from 'mangools-commons/lib/constants/Platforms';
import ErrorCodes, {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/lib/constants/ErrorCodes';

import config from 'appConfig';

import TimeframeService from 'services/TimeframeService';
import KeywordExportService from 'services/KeywordExportService';
import TrackingGroupService from 'services/TrackingGroupService';

import AnnotationSource from 'sources/AnnotationSource';
import AnnouncementsSource from 'sources/AnnouncementsSource';
import KeywordSource from 'sources/KeywordSource';
import ListSource from 'sources/ListSource';
import LocationSource from 'sources/LocationSource';
import ReportSource from 'sources/ReportSource';
import TrackingKeywordsSource from 'sources/TrackingKeywordsSource';
import TrackingSource from 'sources/TrackingSource';
import TrackingStatsSource from 'sources/TrackingStatsSource';
import TrackingTagSource from 'sources/TrackingTagSource';
import VersionSource from 'sources/VersionSource';
import UnleashSource from 'sources/UnleashSource';

import {
    cancelledDeleteKeywordAction,
    cancelledReportDeleteAction,
    cancelledSelectedTrackingKeywordsDeleteAction,
    errorAddKeywordsToTrackingAction,
    errorAnnouncementsAction,
    errorAssignSelectedTagsAction,
    errorBulkAssignSelectedTagsAction,
    errorBulkUnassignSelectedTagsAction,
    errorCreateAnnotationAction,
    errorCreateTagAction,
    errorDeleteAnnotationAction,
    errorDeleteKeywordAction,
    errorDeleteReportAction,
    errorDeleteTagAction,
    errorListsAction,
    errorListsKeywordsAction,
    errorLocationsAction,
    errorNewReportAction,
    errorNewTrackingAction,
    errorReportsAction,
    errorSelectedTrackingKeywordsDeleteAction,
    errorSelectedTrackingKeywordsExportAction,
    errorTrackingDetailAction,
    errorTrackingKeywordDetailAction,
    errorTrackingTagsAction,
    errorTrackingsAction,
    errorTrackingsDeleteAction,
    errorUnassignTagAction,
    errorUpdateAnnotationAction,
    errorUpdateReportAction,
    errorUpdateTagColorAction,
    errorUpdateTagNameAction,
    fetchingAddKeywordsToTrackingAction,
    fetchingAnnouncementsAction,
    fetchingCreateAnnotationAction,
    fetchingDeleteKeywordAction,
    fetchingDeleteReportAction,
    fetchingListsAction,
    fetchingListsKeywordsAction,
    fetchingLocationsAction,
    fetchingNewReportAction,
    fetchingNewTrackingAction,
    fetchingReportsAction,
    fetchingSelectedTrackingKeywordsDeleteAction,
    fetchingSelectedTrackingKeywordsExportAction,
    fetchingTrackingDetailAction,
    fetchingTrackingStatsDetailAction,
    fetchingTrackingTimeframeDataDetailAction,
    fetchingTrackingKeywordDetailAction,
    fetchingTrackingTagsAction,
    fetchingTrackingsAction,
    fetchingUnassignTagAction,
    fetchingUpdateReportAction,
    receivedAddKeywordsToTrackingAction,
    receivedAnnouncementsAction,
    receivedAssignSelectedTagsAction,
    receivedBulkAssignSelectedTagsAction,
    receivedBulkUnassignSelectedTagsAction,
    receivedCreateAnnotationAction,
    receivedCreateTagAction,
    receivedDeleteAnnotationAction,
    receivedDeleteKeywordAction,
    receivedDeleteReportAction,
    receivedDeleteTagAction,
    receivedListsAction,
    receivedListsKeywordsAction,
    receivedLocationsAction,
    receivedNewReportAction,
    receivedNewTrackingAction,
    receivedReportsAction,
    receivedSelectedTrackingKeywordsDeleteAction,
    receivedSelectedTrackingKeywordsExportAction,
    receivedTrackingDetailAction,
    receivedTrackingAllStatsDetailAction,
    receivedTrackingSelectedStatsDetailAction,
    receivedTrackingKeywordDetailAction,
    receivedTrackingTagsAction,
    receivedTrackingsAction,
    receivedUnassignTagAction,
    receivedUpdateAnnotationAction,
    receivedUpdateReportAction,
    receivedUpdateTagColorAction,
    receivedUpdateTagNameAction,
    skippedListsAction,
    skippedNewTrackingAction,
    skippedNewTrackingFillAction,
    skippedTrackingsAction,
    exportingSnapshotImageFinishedAction,
    errorSnapshotImageAction,
    receivedTrackingDomainUpdateAction,
    errorUpdateTrackingDomainAction,
    receivedTrackingShareTokenUpdateAction,
    errorTrackingShareTokenUpdateAction,
    optimisticUpdateTrackingDomainAction,
    revertOptimisticUpdateTrackingDomainAction,
    cancelledSelectedTrackingKeywordsRestoreAction,
    errorTrackingsRestoreAction,
    errorSelectedTrackingKeywordsRestoreAction,
    receivedSelectedTrackingKeywordsRestoreAction,
    fetchingSelectedTrackingKeywordsRestoreAction,
} from 'actions/dataActions';

import { setUnleashSessionAction } from 'actions/userActions';

import {
    closeAddKeywordsPanel,
    closeAddedKeywordsMessage,
    closeCreateTrackingMessage,
    fillNewTrackingFromTemplate,
    requestedAddKeywordsPanelKeywordsAdd,
    requestedNewTrackingKeywordsAdd,
    resetAddKeywordsPanelSelectedLists,
    resetAddKwTagsDropdown,
    resetNewTrackingSelectedLists,
    resetTrackingKeywordDetail,
    setNewTrackingKeywords,
    setNewVersionNotificationShown,
    setTrackingAnnotationDeletingId,
    setTrackingAnnotationUpdatingId,
    setTrackingQuickFilterTagIds,
    showAccessDeniedMessage,
    showAddedKeywordsMessage,
    showDeleteConfirmationMessage,
    showFailureMessage,
    showInvalidShareTokenMessage,
    showNewTrackingInfoMessage,
    showNoConnectionMessage,
    showPricingMessage,
    showTrackingAlreadyExistsMessage,
    showUnauthorizedMessage,
    unselectAllTrackingKeywords,
    updateTagFilter,
    setIsWithDeletedKeywordsSetting,
} from 'actions/uiActions';

import { setDefaultLocation, setDefaultPlatform, setTrackingGroupOrderedKeys } from 'actions/defaultsActions';

import { accessTokenSelector, remainingTrackedKeywordLimitSelector } from 'selectors/userSelectors';

import {
    addKeywordsPanelSelectedListsKeywordIds,
    allTrackingDetailTagsWithAssignedKwCountSortedSelector,
    currentReportNameSelector,
    currentTrackingDomainSelector,
    currentTrackingIdSelector,
    groupedAndSortedTrackingsObjectSelector,
    newTrackingSelectedListsKeywordIds,
    trackingDetailDataSelector,
    trackingDetailIdSelector,
    trackingDetailTimeframePointsObjectSelector,
    trackingFilteredSelectedKeywordIdsSelector,
    trackingFilteredSelectedKeywordsSelector,
    trackingSelector,
    filteredAndSortedTrackingKeywordsSelectorIds,
    trackingsFetchedSelector,
    trackingKeywordDetailDataSelector,
    currentTrackingCreatedAtSelector,
    trackingDetailKeywordsSelector,
} from 'selectors/dataSelectors';

import {
    addKeywordsPanelAssignedTagIdsSelector,
    addKeywordsPanelKeywordsInLimitSelector,
    addKeywordsPanelKeywordsInTrackingSelector,
    newTrackingDomainSelector,
    newTrackingValidSelector,
    newVersionNotificationShownSelector,
    reportsPanelEditAlertTriggersSelector,
    reportsPanelEditEmailsSelector,
    reportsPanelEditNameSelector,
    reportsPanelEditTypeSelector,
    reportsPanelNewAlertTriggersSelector,
    reportsPanelNewEmailsSelector,
    reportsPanelNewGeneratedNameSelector,
    newTrackingCreatedTrackingIdSelector,
    reportsPanelNewNameSelector,
    reportsPanelNewTypeSelector,
    tagsBulkDropdownSelectedIdsSelector,
    tagsDropdownSelectedIdsSelector,
    trackingCurrentKeywordDetailSelector,
    isWithDeletedTrackingsSelector,
    isWithDeletedKeywordsSelector,
} from 'selectors/uiSelectors';
import { validLocationsIdsSelector, defaultPlatformsIdsSelector } from 'selectors/defaultsSelectors';

import {
    reportsDataSelector,
    reportsPanelCurrentReportIdSelector,
    trackingTimeframeSelector,
    trackingQuickFilterSettingsSelector,
    currentQuerySelector,
    addKeywordsPanelSelectedListIdsSelector,
    newTrackingSelectedListIdsSelector,
    areTrackingKeywordsFilteredSelector,
    isReportRouteSelector,
    defaultTrackingGroupOrderedKeysSelector,
    isNewTrackingRouteSelector,
} from 'selectors/commonSelectors';

import { showInfoNotification } from 'sagas/uiSagas';

import { handleUncaught, logError, handleError } from 'sagas/errorSagas';

import Defaults from 'mangools-commons/lib/constants/Defaults';

import ActionTypes from 'constants/ActionTypes';
import Strings from 'constants/Strings';
import DeleteResourceTypes from 'constants/DeleteResourceTypes';
import NewTrackingCloneTypes from 'constants/NewTrackingCloneTypes';
import { AuthError } from 'constants/Errors';

import { watchTrackingRequests } from 'sagas/data/trackingSagas';
import { watchKwfinderListRequests } from 'sagas/data/kwfinderListSagas';
import { watchSuggestionsRequests } from 'sagas/data/suggestionsSaga';
import RoutePaths from 'constants/RoutePaths';
import { requestedNavigationAction } from 'actions/routerActions';
import { NewTrackingStep } from 'components/newTracking/step/NewTrackingStep';
import { newTrackingConfigDataSelector, newTrackingKeywordsSelector } from 'selectors/sharedSelectors';
import { gtmTrack } from 'actions/analyticsActions';
import { analyticsActions, analyticsEvents } from 'constants/analytics';
import UsercomService from 'mangools-commons/lib/services/UsercomService';
import { userEventNames } from 'constants/usercom';
import { ErrorTypes } from 'constants/ErrorTypes';

const EDIT_TRACKING = 'EDIT_TRACKING';
const NEW_TRACKING = 'NEW_TRACKING';
const TRACKING_DETAIL_FILTER_SEARCH_DEBOUNCE_MS = 400;
const MAX_REQUEST_TIMEOUT = 60 * 1000

const EXPORT_PREFIX = 'serpwatcher_';

const fetchTrackingsData = handleUncaught(
    function* fetchTrackingsData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const isWithDeleted = yield select(isWithDeletedTrackingsSelector);
        const alreadyFetched = yield select(trackingsFetchedSelector);

        if (!isNil(accessToken) || alreadyFetched === true) {
            yield put(fetchingTrackingsAction());

            const { result, _timeout } = yield race({
                result: call(TrackingSource.getData, { accessToken, isWithDeleted }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedTrackingsAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorTrackingsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchTrackingsData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorTrackingsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorTrackingsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorTrackingsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchTrackingsDataSaga', payload);
                            } else {
                                yield call(fetchTrackingsData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorTrackingsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_trackings }));
                                yield call(logError, 'FetchTrackingsDataSaga', payload);
                            } else {
                                yield call(fetchTrackingsData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchTrackingsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedTrackingsAction());
        }
    },
    function* onError() {
        yield put(errorTrackingsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchTrackingKeywordDetail = handleUncaught(
    function* fetchTrackingKeywordDetail(action, retrying = false) {
        const { id, report, shareToken } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const timeframe = yield select(trackingTimeframeSelector);
        const { from, to } = yield call(TimeframeService.translateToISO, timeframe);
        const trackingId = yield select(trackingDetailIdSelector);

        if (report === true || !isNil(accessToken)) {
            yield put(fetchingTrackingKeywordDetailAction());

            const { result, _timeout } = yield race({
                result: call(
                    TrackingKeywordsSource.getDetail,
                    {
                        accessToken,
                        report,
                        shareToken,
                    },
                    {
                        trackingId,
                        id,
                        from,
                        to,
                    },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedTrackingKeywordDetailAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorTrackingKeywordDetailAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchTrackingKeywordDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorTrackingKeywordDetailAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorTrackingKeywordDetailAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorTrackingKeywordDetailAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorTrackingKeywordDetailAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchTrackingKeywordDetailDataSaga', payload);
                            } else {
                                yield call(fetchTrackingKeywordDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorTrackingKeywordDetailAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.fetch_tracking_keyword }),
                                );
                                yield call(logError, 'FetchTrackingKeywordDetailDataSaga', payload);
                            } else {
                                yield call(fetchTrackingKeywordDetail, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingKeywordDetailAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchTrackingKeywordDetailDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorTrackingKeywordDetailAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchTrackingStatsDetail = handleUncaught(
    function* fetchTrackingStatsDetail(action, retrying = false) {
        const { report } = action.payload;
        const { id, from, to, shareToken } = action.payload.options;
        const accessToken = yield select(accessTokenSelector);
        const areKwsFiltered = yield select(areTrackingKeywordsFilteredSelector);
        const kwIds = yield select(filteredAndSortedTrackingKeywordsSelectorIds);
        const kwOriginal = yield select(trackingDetailKeywordsSelector);
        const kwIdsOriginal = kwOriginal.map(kw => kw.id);

        if (report === true || !isNil(accessToken)) {
            yield put(fetchingTrackingStatsDetailAction());
            if (action.payload.isTimeframeChanged) {
                yield put(fetchingTrackingTimeframeDataDetailAction());
            }

            const { result, _timeout } = yield race({
                result: call(
                    TrackingStatsSource.getDetail,
                    {
                        accessToken,
                        report,
                        shareToken,
                    },
                    {
                        id,
                        from,
                        to,
                        kwIds: areKwsFiltered ? kwIds : undefined,
                    },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    if (areKwsFiltered) {
                        const response = yield race({
                            result: call(
                                TrackingStatsSource.getDetail,
                                {
                                    accessToken,
                                    report,
                                    shareToken,
                                },
                                {
                                    id,
                                    from,
                                    to,
                                    kwIds: kwIdsOriginal,
                                },
                            ),
                            _timeout: delay(MAX_REQUEST_TIMEOUT),
                        });

                        if (!isNil(response.result)) {
                            yield put(receivedTrackingAllStatsDetailAction(response.result.payload));
                        }
                        yield put(receivedTrackingSelectedStatsDetailAction(payload));
                    } else {
                        yield put(receivedTrackingAllStatsDetailAction(payload));
                    }

                    // Refetch kw detail data if detail is opened
                    const currentKeywordDetailId = yield select(trackingCurrentKeywordDetailSelector);

                    if (!isNil(currentKeywordDetailId)) {
                        yield call(fetchTrackingKeywordDetail, {
                            payload: { id: currentKeywordDetailId, report, shareToken },
                        });
                    }
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchTrackingStatsDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.NOT_FOUND: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.tracking_not_found_error,
                                }),
                            );
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorTrackingDetailAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'fetchTrackingStatsDetailDataSaga', payload);
                            } else {
                                yield call(fetchTrackingStatsDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));

                                if (payload.text === AuthError.text) {
                                    yield put(
                                        showInvalidShareTokenMessage({
                                            details: Strings.messages.failure.fetch_trackings_invalid_share_token,
                                        }),
                                    );
                                } else {
                                    yield put(showFailureMessage({ details: Strings.messages.failure.fetch_tracking }));
                                }

                                yield call(logError, 'fetchTrackingStatsDetailDataSaga', payload);
                            } else {
                                yield call(fetchTrackingStatsDetail, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(
                    showFailureMessage({
                        details: Strings.messages.failure.show_list_data_error,
                    }),
                );
                yield put(errorTrackingDetailAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'fetchTrackingStatsDetailDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorTrackingDetailAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const reactToTrackingDetailFilters = handleUncaught(function* reactToTrackingDetailFilters() {
    const query = yield select(currentQuerySelector);
    const isReportRoute = yield select(isReportRouteSelector);
    const currentTrackingCreatedAt = yield select(currentTrackingCreatedAtSelector);

    const parseReportParams = (rawParams, createdAt) => {
        if (!isNil(rawParams.from) && !isNil(rawParams.to)) {
            return {
                from: rawParams.from,
                id: rawParams.id,
                shareToken: rawParams.token,
                to: rawParams.to,
                predefined: true,
            };
        } else {
            const defaultTimeframe = TimeframeService.getDefault(createdAt);
            const { from, to } = TimeframeService.translateToISO(defaultTimeframe);

            return {
                from,
                id: rawParams.id,
                shareToken: rawParams.token,
                to,
                predefined: false,
            };
        }
    };

    const parseParams = (rawParams, createdAt) => {
        let params = {
            id: rawParams.id,
            kwfinderListId: rawParams.list_id,
        };

        if (!isNil(rawParams.from) && !isNil(rawParams.to)) {
            params = { ...params, ...{ from: rawParams.from, to: rawParams.to } };
        } else {
            const defaultTimeframe = TimeframeService.getDefault(createdAt);
            const { from, to } = TimeframeService.translateToISO(defaultTimeframe);
            params = {
                ...params,
                ...{
                    from,
                    to,
                },
            };
        }

        return params;
    };

    const isRequiredParamsPresent = !isNil(query) && !isNil(query.id);

    if (isRequiredParamsPresent) {
        let params = null;

        if (isReportRoute === true) {
            params = parseReportParams(query, null);
        } else {
            params = parseParams(query, currentTrackingCreatedAt);
        }

        const { id, from, to, shareToken } = params;

        yield call(fetchTrackingStatsDetail, {
            type: ActionTypes.DATA_TRACKING_STATS_DETAIL_REQUESTED,
            error: false,
            payload: {
                options: { id, from, to, shareToken },
                report: isReportRoute,
            },
        });
    }
});

const fetchTrackingDetail = handleUncaught(
    function* fetchTrackingDetail(action, retrying = false) {
        const { report } = action.payload;
        const { id, from, to, shareToken } = action.payload.options;
        const accessToken = yield select(accessTokenSelector);
        const isWithDeletedKeywords = yield select(isWithDeletedKeywordsSelector);

        if (isWithDeletedKeywords) {
            yield put(setIsWithDeletedKeywordsSetting(false));
        }

        if (report === true || !isNil(accessToken)) {
            yield put(fetchingTrackingDetailAction());
            yield call(fetchTrackingStatsDetail, action);

            const { result, _timeout } = yield race({
                result: call(
                    TrackingSource.getDetail,
                    {
                        accessToken,
                        report,
                        shareToken,
                        isWithDeleted: false,
                    },
                    {
                        id,
                        from,
                        to,
                    },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedTrackingDetailAction(payload));

                    // Refetch kw detail data if detail is opened
                    const currentKeywordDetailId = yield select(trackingCurrentKeywordDetailSelector);

                    if (!isNil(currentKeywordDetailId)) {
                        yield call(fetchTrackingKeywordDetail, {
                            payload: { id: currentKeywordDetailId, report, shareToken },
                        });
                    }
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchTrackingDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.NOT_FOUND: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.tracking_not_found_error,
                                }),
                            );
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorTrackingDetailAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchTrackingDetailDataSaga', payload);
                            } else {
                                yield call(fetchTrackingDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));

                                if (payload.text === AuthError.text) {
                                    yield put(
                                        showInvalidShareTokenMessage({
                                            details: Strings.messages.failure.fetch_trackings_invalid_share_token,
                                        }),
                                    );
                                } else {
                                    yield put(showFailureMessage({ details: Strings.messages.failure.fetch_tracking }));
                                }

                                yield call(logError, 'FetchTrackingDetailDataSaga', payload);
                            } else {
                                yield call(fetchTrackingDetail, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingDetailAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchTrackingDetailDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorTrackingDetailAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const refetchTrackingDetail = handleUncaught(
    function* refetchTrackingDetail(action, retrying = false) {
        const { report, isWithRecheckDeleted } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const isWithDeletedKeywords = yield select(isWithDeletedKeywordsSelector);

        if (report === true || !isNil(accessToken)) {
            const tracking = yield select(trackingDetailDataSelector);
            if (!tracking.id) {
                return;
            }
            yield put(fetchingTrackingDetailAction());
            const timeframe = yield select(trackingTimeframeSelector);
            const translated = TimeframeService.translateToISO({
                from: timeframe.from,
                to: timeframe.to,
                type: timeframe.type,
            });

            const { result, _timeout } = yield race({
                result: call(
                    TrackingSource.getDetail,
                    {
                        accessToken,
                        report,
                        shareToken: tracking.shareToken,
                        isWithDeleted: isWithDeletedKeywords,
                    },
                    {
                        id: tracking.id,
                        from: translated.from,
                        to: translated.to,
                    },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    if (isWithRecheckDeleted) {
                        const hasDeletedKw = payload.keywords.find(({ isDeleted }) => isDeleted);
                        if (isWithDeletedKeywords && !hasDeletedKw) {
                            yield put(setIsWithDeletedKeywordsSetting(false));
                        }
                    }
                    yield put(receivedTrackingDetailAction(payload));

                    // Refetch kw detail data if detail is opened
                    const currentKeywordDetailId = yield select(trackingCurrentKeywordDetailSelector);

                    if (!isNil(currentKeywordDetailId)) {
                        yield call(fetchTrackingKeywordDetail, {
                            payload: { id: currentKeywordDetailId, report, shareToken: tracking.shareToken },
                        });
                    }
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(refetchTrackingDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorTrackingDetailAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorTrackingDetailAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'RefetchTrackingDetailDataSaga', payload);
                            } else {
                                yield call(refetchTrackingDetail, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorTrackingDetailAction(payload));

                                if (payload.text === AuthError.text) {
                                    yield put(
                                        showInvalidShareTokenMessage({
                                            details: Strings.messages.failure.fetch_trackings_invalid_share_token,
                                        }),
                                    );
                                } else {
                                    yield put(showFailureMessage({ details: Strings.messages.failure.fetch_tracking }));
                                }

                                yield call(logError, 'RefetchTrackingDetailDataSaga', payload);
                            } else {
                                yield call(refetchTrackingDetail, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingDetailAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'RefetchTrackingDetailDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorTrackingDetailAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

/* eslint-disable max-len */
const createNewTracking = handleUncaught(
    function* createNewTracking(action, retrying = false) {
        const validForm = yield select(newTrackingValidSelector);
        const remainingKeywordsLimit = yield select(remainingTrackedKeywordLimitSelector);

        if (validForm === true && remainingKeywordsLimit > 0) {
            const domain = yield select(newTrackingDomainSelector);
            // HERE
            const platformIds = yield select(defaultPlatformsIdsSelector);
            const locationIds = yield select(validLocationsIdsSelector);
            const keywords = yield select(newTrackingKeywordsSelector);
            let gMyBusinessData = yield select(newTrackingConfigDataSelector);
            const limitedKeywords = keywords.slice(0, remainingKeywordsLimit);

            if (gMyBusinessData && gMyBusinessData.forced_place_id) {
                gMyBusinessData = {
                    place_id: gMyBusinessData.forced_place_id,
                };
            }

            // Check if tracking with the same `domain` + `locationId` + `platformId`
            // don't already exists, if it does, show already exists message with
            // link to this tracking and a note to use `Add Keywords` + `tags` features instead.

            const groupedTrackingsObject = yield select(groupedAndSortedTrackingsObjectSelector);

            if (platformIds.length === 1 && locationIds.length === 1) {
                const platformId = platformIds[0];
                const locationId = locationIds[0];

                const groupKey = TrackingGroupService.generateKey({ domain, locationId });
                const platform = PlatformFinderService.findById(platformId);
                const groupItem = groupedTrackingsObject[groupKey];
                if (!isNil(groupItem) && !isNil(groupItem[platform.label])) {
                    const tracking = groupItem[platform.label];

                    yield put(
                        showTrackingAlreadyExistsMessage({
                            trackingId: tracking.id,
                            keywords: limitedKeywords,
                        }),
                    );
                    yield put(skippedNewTrackingAction());
                    return;
                }
            }

            const groupKeys = locationIds.map(locationId => TrackingGroupService.generateKey({ domain, locationId }));

            const options = {
                domain,
                platformIds,
                locationIds,
                keywords: limitedKeywords,
                trackingConfig: gMyBusinessData,
            };
            const accessToken = yield select(accessTokenSelector);

            if (!isNil(accessToken)) {
                yield put(fetchingNewTrackingAction());

                const { result, _timeout } = yield race({
                    result: call(TrackingSource.create, accessToken, options),
                    // _timeout: delay(MAX_REQUEST_TIMEOUT),
                });

                if (!isNil(result)) {
                    const { error, payload } = result;
                    if (!error) {
                        const { reports, data } = payload.reduce(
                            (prev, item) => {
                                return {
                                    ...prev,
                                    reports: [...prev.reports, ...item.reports],
                                    data: [...prev.data, item.data],
                                };
                            },
                            {
                                reports: [],
                                data: [],
                            },
                        );
                        const trackingGroupOrdereredKeys = yield select(defaultTrackingGroupOrderedKeysSelector);

                        yield put(
                            receivedNewTrackingAction({
                                data,
                                reports,
                                estimatedTimeProcessing: payload[0].estimatedTimeProcessing,
                            }),
                        );
                        yield put(
                            setTrackingGroupOrderedKeys({
                                trackingGroupKeys: [...groupKeys, ...trackingGroupOrdereredKeys],
                                oldIndex: 0,
                                newIndex: 0,
                            }),
                        );

                        yield put(requestedNavigationAction(RoutePaths.NEW, { step: NewTrackingStep.Complete }));
                    } else {
                        switch (payload.status) {
                            case ErrorCodes.FETCH_ERROR: {
                                if (retrying === true) {
                                    yield put(errorNewTrackingAction(payload));
                                    yield put(showNoConnectionMessage());
                                } else {
                                    // Wait for CONNECTION_RETRY_DELAY and try again
                                    yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                    yield call(createNewTracking, action, true);
                                }
                                break;
                            }
                            case ErrorCodes.CONFLICT: {
                                yield put(errorNewTrackingAction(payload));

                                // Refetch trackings data, there are some which are not in our state yet
                                yield call(fetchTrackingsData, null, false);

                                // Reselect grouped trackings, and get the opposing platform tracking
                                const newGroupedTrackingsObject = yield select(groupedAndSortedTrackingsObjectSelector);
                                const newGroupItem = newGroupedTrackingsObject[groupKeys[0]];
                                const platform = PlatformFinderService.findById(platformIds[0]);
                                const newTracking = newGroupItem[platform.label];

                                // Recalculate the remaining keywords
                                const newRemainingKeywordsLimit = yield select(remainingTrackedKeywordLimitSelector);
                                const newLimitedKeywords = keywords.slice(0, newRemainingKeywordsLimit);

                                // Show tracking already exists
                                yield put(
                                    showTrackingAlreadyExistsMessage({
                                        trackingId: newTracking.id,
                                        keywords: newLimitedKeywords,
                                    }),
                                );

                                // Skip new tracking creation
                                yield put(skippedNewTrackingAction());
                                break;
                            }
                            case ErrorCodes.ACCESS_DENIED: {
                                yield put(errorNewTrackingAction(payload));
                                yield put(showAccessDeniedMessage());
                                break;
                            }
                            case ErrorCodes.UNAUTHORIZED: {
                                yield put(errorNewTrackingAction(payload));
                                yield put(showUnauthorizedMessage());
                                break;
                            }
                            case ErrorCodes.TOO_MANY_REQUESTS: {
                                yield put(errorNewTrackingAction(payload));

                                if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.too_many_requests_error,
                                        }),
                                    );
                                }

                                break;
                            }
                            case ErrorCodes.UNPROCESSABLE_ENTITY: {
                                yield put(errorNewTrackingAction(payload));
                                yield put(showPricingMessage()); // Not enough remaining tracked keywords
                                yield call(logError, 'CreateNewTrackingDataSaga', payload);
                                break;
                            }
                            case ErrorCodes.SERVICE_UNAVAILABLE: {
                                if (retrying === true) {
                                    yield put(errorNewTrackingAction(payload));
                                    yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                    yield call(logError, 'CreateNewTrackingDataSaga', payload);
                                } else {
                                    yield call(createNewTracking, action, true);
                                }
                                break;
                            }
                            case ErrorCodes.INTERNAL_SERVER_ERROR:
                            default: {
                                if (retrying === true) {
                                    yield put(errorNewTrackingAction(payload));
                                    yield put(
                                        showFailureMessage({
                                            details: Strings.messages.failure.create_tracking_error,
                                        }),
                                    );
                                    yield call(logError, 'CreateNewTrackingDataSaga', payload);
                                } else {
                                    yield call(createNewTracking, action, true);
                                }
                                break;
                            }
                        }
                    }
                } else {
                    yield put(errorNewTrackingAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                    yield put(showFailureMessage({ details: Strings.messages.failure.create_tracking_error }));
                    yield call(logError, 'CreateNewTrackingDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
                }
            }
        } else {
            yield put(skippedNewTrackingAction());
        }
    },
    function* onError() {
        yield put(errorNewTrackingAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.create_tracking_error }));
    },
);
/* eslint-enable max-len */

// NOTE: this is changing the default location and plaform
// which means it is used when creating new tracking.
const fillNewTracking = handleUncaught(function* fillNewTracking(action, retrying = false) {
    const accessToken = yield select(accessTokenSelector);
    const { trackingId } = action.payload;

    let tracking = null;
    let successFetch = null;
    let _errorFetch = null; // eslint-disable-line no-underscore-dangle

    const fetched = yield select(trackingsFetchedSelector);

    if (fetched === false) {
        ({ successFetch, _errorFetch } = yield race({
            successFetch: take(ActionTypes.DATA_TRACKINGS_RECEIVED),
            _errorFetch: take(ActionTypes.DATA_TRACKINGS_ERROR),
        }));
    }

    if (fetched || successFetch) {
        tracking = yield select(trackingSelector, trackingId);
    }

    if (!isNil(accessToken) && !isNil(tracking)) {
        yield put(fetchingListsKeywordsAction());

        const { result, _timeout } = yield race({
            result: call(TrackingKeywordsSource.getAllKeywords, accessToken, { trackingId }),
            _timeout: delay(MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                const remainingTrackedKeywordLimit = yield select(remainingTrackedKeywordLimitSelector);
                // Set location, platform and domain
                yield put(setDefaultLocation([tracking.location]));
                yield put(setDefaultPlatform(tracking.platformId === DESKTOP.id ? [DESKTOP] : [MOBILE]));
                yield put(
                    fillNewTrackingFromTemplate({ domain: tracking.domain, trackingConfig: tracking.trackingConfig }),
                );

                // Fetch and set kws
                yield put(receivedListsKeywordsAction(payload));
                yield put(
                    setNewTrackingKeywords({
                        keywordsInLimit: slice(0, remainingTrackedKeywordLimit, payload),
                        keywordsNotInLimit: slice(remainingTrackedKeywordLimit, Infinity, payload),
                    }),
                );
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorListsKeywordsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fillNewTracking, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorListsKeywordsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNAUTHORIZED: {
                        yield put(errorListsKeywordsAction(payload));
                        yield put(showUnauthorizedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorListsKeywordsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorListsKeywordsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FillNewTrackingDataSaga', payload);
                        } else {
                            yield call(fillNewTracking, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorListsKeywordsAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.fill_new_tracking_keywords,
                                }),
                            );
                            yield call(logError, 'FillNewTrackingDataSaga', payload);
                        } else {
                            yield call(fillNewTracking, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorListsKeywordsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FillNewTrackingDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    } else {
        yield put(skippedNewTrackingFillAction());
    }
});

const cloneAndCreateNewTracking = handleUncaught(function* cloneAndCreateNewTracking(action, retrying = false) {
    const accessToken = yield select(accessTokenSelector);
    const { cloneType, templateTrackingId } = action.payload;

    const templateTracking = yield select(trackingSelector, templateTrackingId);
    const remainingKeywordsLimit = yield select(remainingTrackedKeywordLimitSelector);

    if (isNil(templateTracking) || templateTracking.trackedKeywordsCount > remainingKeywordsLimit) {
        yield put(closeCreateTrackingMessage());
        yield put(showPricingMessage());
        yield put(skippedNewTrackingAction());
    } else if (!isNil(accessToken)) {
        yield put(fetchingNewTrackingAction());

        const { result, _timeout } = yield race({
            result: call(TrackingKeywordsSource.getAllKeywords, accessToken, {
                trackingId: templateTrackingId,
            }),
            _timeout: delay(MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                let options = null;

                // Clone based on `cloneType`
                if (cloneType === NewTrackingCloneTypes.OPPOSITE_PLATFORM) {
                    // Call new tracking with keywords in `payload`,
                    // opposite platform than template tracking and other
                    // params the same

                    options = {
                        domain: templateTracking.domain,
                        platformIds: [templateTracking.platformId === DESKTOP.id ? MOBILE.id : DESKTOP.id],
                        locationIds: [templateTracking.location.id],
                        keywords: payload,
                        trackingConfig: templateTracking.trackingConfig,
                    };
                }

                if (!isNil(options)) {
                    const { newResult, _newTimeout } = yield race({
                        newResult: call(TrackingSource.create, accessToken, options),
                        _newTimeout: delay(MAX_REQUEST_TIMEOUT),
                    });

                    // Close create message
                    yield put(closeCreateTrackingMessage());

                    if (!isNil(newResult)) {
                        const { error: newError, payload: newPayload } = newResult;

                        if (!newError) {
                            const { reports, data } = newPayload.reduce(
                                (prev, item) => {
                                    return {
                                        ...prev,
                                        reports: [...prev.reports, ...item.reports],
                                        data: [...prev.data, item.data],
                                    };
                                },
                                {
                                    reports: [],
                                    data: [],
                                },
                            );

                            yield put(
                                receivedNewTrackingAction({
                                    data,
                                    reports,
                                    estimatedTimeProcessing: newPayload[0]?.estimatedTimeProcessing,
                                }),
                            );
                            yield put(
                                showNewTrackingInfoMessage({
                                    postponedProcessing: newPayload[0]?.postponedProcessing,
                                    estimatedTimeProcessing: newPayload[0]?.estimatedTimeProcessing,
                                }),
                            );
                        } else {
                            switch (newPayload.status) {
                                case ErrorCodes.FETCH_ERROR: {
                                    if (retrying === true) {
                                        yield put(errorNewTrackingAction(newPayload));
                                        yield put(showNoConnectionMessage());
                                    } else {
                                        // Wait for CONNECTION_RETRY_DELAY and try again
                                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                        yield call(cloneAndCreateNewTracking, action, true);
                                    }
                                    break;
                                }
                                case ErrorCodes.ACCESS_DENIED: {
                                    yield put(errorNewTrackingAction(newPayload));
                                    yield put(showAccessDeniedMessage());
                                    break;
                                }
                                case ErrorCodes.UNAUTHORIZED: {
                                    yield put(errorNewTrackingAction(newPayload));
                                    yield put(showUnauthorizedMessage());
                                    break;
                                }
                                case ErrorCodes.TOO_MANY_REQUESTS: {
                                    yield put(errorNewTrackingAction(newPayload));

                                    if (newPayload.type === ErrorTypes.REPEAT_REQUEST) {
                                        yield put(
                                            showFailureMessage({
                                                details: Strings.messages.failure.too_many_requests_error,
                                            }),
                                        );
                                    }

                                    break;
                                }
                                case ErrorCodes.UNPROCESSABLE_ENTITY: {
                                    yield put(errorNewTrackingAction(newPayload));
                                    yield put(showPricingMessage()); // Not enough remaining tracked keywords
                                    yield call(logError, 'CloneAndCreateNewTrackingSaga', newPayload);
                                    break;
                                }
                                case ErrorCodes.SERVICE_UNAVAILABLE: {
                                    if (retrying === true) {
                                        yield put(errorNewTrackingAction(newPayload));
                                        yield put(
                                            showFailureMessage({
                                                details: Strings.messages.failure.maintenance,
                                            }),
                                        );
                                        yield call(logError, 'CloneAndCreateNewTrackingSaga', newPayload);
                                    } else {
                                        yield call(cloneAndCreateNewTracking, action, true);
                                    }
                                    break;
                                }
                                case ErrorCodes.INTERNAL_SERVER_ERROR:
                                default: {
                                    if (retrying === true) {
                                        yield put(errorNewTrackingAction(newPayload));
                                        yield put(
                                            showFailureMessage({
                                                details: Strings.messages.failure.create_tracking_error,
                                            }),
                                        );
                                        yield call(logError, 'CloneAndCreateNewTrackingSaga', newPayload);
                                    } else {
                                        yield call(cloneAndCreateNewTracking, action, true);
                                    }
                                    break;
                                }
                            }
                        }
                    } else {
                        yield put(errorNewTrackingAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                        yield put(showFailureMessage({ details: Strings.messages.failure.create_tracking_error }));
                        yield call(logError, 'CloneAndCreateNewTrackingSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
                    }
                } else {
                    yield put(skippedNewTrackingAction());
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(cloneAndCreateNewTracking, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNAUTHORIZED: {
                        yield put(showUnauthorizedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'CloneAndCreateNewTrackingSaga', payload);
                        } else {
                            yield call(cloneAndCreateNewTracking, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(showFailureMessage({ details: Strings.messages.failure.new_tracking_clone }));
                            yield call(logError, 'CloneAndCreateNewTrackingSaga', payload);
                        } else {
                            yield call(cloneAndCreateNewTracking, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorListsKeywordsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'CloneAndCreateNewTrackingSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    } else {
        yield put(skippedNewTrackingAction());
    }
});

const deleteSelectedTrackedKeywords = handleUncaught(
    function* deleteSelectedTrackedKeywords(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);
        const keywordIds = yield select(trackingFilteredSelectedKeywordIdsSelector);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(
                showDeleteConfirmationMessage({
                    resourceName: `${keywordIds.length}`,
                    resourceType: DeleteResourceTypes.TRACKED_KEYWORDS,
                }),
            );

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(fetchingSelectedTrackingKeywordsDeleteAction());

            const { result, _timeout } = yield race({
                result: call(TrackingKeywordsSource.delete, accessToken, { trackingId, keywordIds }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    // Reset current kw detail and selected kws
                    yield put(resetTrackingKeywordDetail());
                    yield put(unselectAllTrackingKeywords());

                    // Let reducers remove selected kw ids
                    yield put(receivedSelectedTrackingKeywordsDeleteAction({ keywordIds, trackingId }));

                    const kwCount = keywordIds.length;
                    const kwLabel = kwCount > 1 ? 'keywords' : 'keyword';
                    const wasLabel = kwCount > 1 ? 'were' : 'was';

                    // Show notification
                    yield call(
                        showInfoNotification,
                        `<strong>${kwCount} ${kwLabel}</strong> ${wasLabel} successfully removed.`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorSelectedTrackingKeywordsDeleteAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteSelectedTrackedKeywords, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorSelectedTrackingKeywordsDeleteAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorSelectedTrackingKeywordsDeleteAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorSelectedTrackingKeywordsDeleteAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorSelectedTrackingKeywordsDeleteAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteSelectedTrackedKeywordsSaga', payload);
                            } else {
                                yield call(deleteSelectedTrackedKeywords, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorSelectedTrackingKeywordsDeleteAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.delete_selected_tracking_keywords_error,
                                    }),
                                );
                                yield call(logError, 'DeleteSelectedTrackedKeywordsSaga', payload);
                            } else {
                                yield call(deleteSelectedTrackedKeywords, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingsDeleteAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(
                    showFailureMessage({
                        details: Strings.messages.failure.delete_selected_tracking_keywords_error,
                    }),
                );
                yield call(logError, 'DeleteSelectedTrackedKeywordsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledSelectedTrackingKeywordsDeleteAction({ keywordIds, trackingId }));
        }
    },
    function* onError() {
        yield put(errorTrackingsDeleteAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_selected_tracking_keywords_error }));
    },
);

const restoreSelectedTrackedKeywords = handleUncaught(
    function* restoreSelectedTrackedKeywords(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);
        const keywordIds = yield select(trackingFilteredSelectedKeywordIdsSelector);

        const confirm = true;

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(fetchingSelectedTrackingKeywordsRestoreAction());

            const { result, _timeout } = yield race({
                result: call(TrackingKeywordsSource.restore, accessToken, { trackingId, keywordIds }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    // Reset current kw detail and selected kws
                    yield put(unselectAllTrackingKeywords());

                    // Let reducers remove selected kw ids
                    yield put(
                        receivedSelectedTrackingKeywordsRestoreAction({
                            keywordIds,
                            trackingId,
                            isWithRecheckDeleted: true,
                        }),
                    );

                    const kwCount = keywordIds.length;
                    const kwLabel = kwCount > 1 ? 'keywords' : 'keyword';
                    const wasLabel = kwCount > 1 ? 'were' : 'was';

                    // Show notification
                    yield call(
                        showInfoNotification,
                        `<strong>${kwCount} ${kwLabel}</strong> ${wasLabel} successfully restored.`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorSelectedTrackingKeywordsRestoreAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(restoreSelectedTrackedKeywords, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorSelectedTrackingKeywordsRestoreAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorSelectedTrackingKeywordsRestoreAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.UNPROCESSABLE_ENTITY: {
                            yield put(errorSelectedTrackingKeywordsRestoreAction(payload));
                            yield put(showPricingMessage()); // Not enough remaining tracked keywords
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorSelectedTrackingKeywordsRestoreAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorSelectedTrackingKeywordsRestoreAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'RestoreSelectedTrackedKeywordsSaga', payload);
                            } else {
                                yield call(restoreSelectedTrackedKeywords, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorSelectedTrackingKeywordsRestoreAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.restore_selected_tracking_keywords_error,
                                    }),
                                );
                                yield call(logError, 'RestoreSelectedTrackedKeywordsSaga', payload);
                            } else {
                                yield call(restoreSelectedTrackedKeywords, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingsRestoreAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(
                    showFailureMessage({
                        details: Strings.messages.failure.restore_selected_tracking_keywords_error,
                    }),
                );
                yield call(logError, 'RestoreSelectedTrackedKeywordsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledSelectedTrackingKeywordsRestoreAction({ keywordIds, trackingId }));
        }
    },
    function* onError() {
        yield put(errorTrackingsRestoreAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.restore_selected_tracking_keywords_error }));
    },
);

const fetchLocations = handleUncaught(
    function* fetchLocations(action, retrying = false) {
        yield put(fetchingLocationsAction());
        const accessToken = yield select(accessTokenSelector);
        const query = action.payload;
        const options = { accessToken, query };

        const { result, _timeout } = yield race({
            result: call(LocationSource.getData, options),
            _timeout: delay(MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedLocationsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorLocationsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorLocationsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchLocationsSaga', payload);
                        } else {
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorLocationsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
                            yield call(logError, 'FetchLocationsSaga', payload);
                        } else {
                            yield call(fetchLocations, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorLocationsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
            yield call(logError, 'FetchLocationsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorLocationsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_locations_error }));
    },
);

const fetchListData = handleUncaught(
    function* fetchListData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingListsAction());

            const { result, _timeout } = yield race({
                result: call(ListSource.getData, accessToken),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedListsAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorListsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchListData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorListsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorListsAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorListsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorListsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchListsDataSaga', payload);
                            } else {
                                yield call(fetchListData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorListsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_lists_error }));
                                yield call(logError, 'FetchListsDataSaga', payload);
                            } else {
                                yield call(fetchListData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorListsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchListsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedListsAction());
        }
    },
    function* onError() {
        yield put(errorListsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchListKeywordsData = handleUncaught(
    function* fetchListKeywordsData({ keywordIds, addingType, listCount, retrying = false }) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingListsKeywordsAction());

            const { result, _timeout } = yield race({
                result: call(KeywordSource.getData, accessToken, keywordIds),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedListsKeywordsAction(payload));

                    if (addingType === NEW_TRACKING) {
                        yield put(requestedNewTrackingKeywordsAdd({ keywords: payload, listCount }));
                    } else if (addingType === EDIT_TRACKING) {
                        yield put(requestedAddKeywordsPanelKeywordsAdd({ keywords: payload, listCount }));
                    }
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorListsKeywordsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchListKeywordsData, { keywordIds, addingType, retrying: true });
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorListsKeywordsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorListsKeywordsAction(payload));
                            yield put(showUnauthorizedMessage());
                            yield call(logError, 'FetchListKeywordsDataSaga', payload);
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorListsKeywordsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorListsKeywordsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchListKeywordsDataSaga', payload);
                            } else {
                                yield call(fetchListKeywordsData, { keywordIds, addingType, retrying: true });
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorListsKeywordsAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.fetch_lists_keywords_error,
                                    }),
                                );
                                yield call(logError, 'FetchListKeywordsDataSaga', payload);
                            } else {
                                yield call(fetchListKeywordsData, { keywordIds, addingType, retrying: true });
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorListsKeywordsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchListKeywordsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorListsKeywordsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

function* fetchAddKeywordsPanelListKeywordsData() {
    const keywordIds = yield select(addKeywordsPanelSelectedListsKeywordIds);
    const listIds = yield select(addKeywordsPanelSelectedListIdsSelector);

    yield put(
        gtmTrack({
            action: analyticsActions.CONFIRM,
            event: analyticsEvents.IMPORT_KWF_LIST,
        }),
    );
    UsercomService.createEvent({ eventName: userEventNames.IMPORT_KWF_LIST });

    yield call(fetchListKeywordsData, {
        keywordIds,
        addingType: EDIT_TRACKING,
        listCount: listIds.length,
        retrying: false,
    });
    yield put(resetAddKeywordsPanelSelectedLists()); // Reset selected lists ids
}

function* fetchNewTrackingListKeywordsData() {
    const keywordIds = yield select(newTrackingSelectedListsKeywordIds);
    const listIds = yield select(newTrackingSelectedListIdsSelector);

    yield put(
        gtmTrack({
            action: analyticsActions.CONFIRM,
            event: analyticsEvents.IMPORT_KWF_LIST,
        }),
    );
    UsercomService.createEvent({ eventName: userEventNames.IMPORT_KWF_LIST });

    yield call(fetchListKeywordsData, {
        keywordIds,
        addingType: NEW_TRACKING,
        listCount: listIds.length,
        retrying: false,
    });
    yield put(resetNewTrackingSelectedLists()); // Reset selected lists ids
}

const addKeywordsToTracking = handleUncaught(
    function* addKeywordsToTracking(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        const keywordsInLimit = yield select(addKeywordsPanelKeywordsInLimitSelector);
        const keywordsInTracking = yield select(addKeywordsPanelKeywordsInTrackingSelector);
        const keywords = concat(keywordsInLimit, keywordsInTracking);
        const tagIds = yield select(addKeywordsPanelAssignedTagIdsSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken) && keywords.length > 0) {
            yield put(fetchingAddKeywordsToTrackingAction());
            yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(TrackingKeywordsSource.add, accessToken, { keywords, tagIds, trackingId }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const { postponedProcessing, estimatedTimeProcessing, data } = payload;
                    const trackingTags = yield select(allTrackingDetailTagsWithAssignedKwCountSortedSelector);

                    yield put(
                        receivedAddKeywordsToTrackingAction({
                            assignedTagIds: tagIds,
                            data,
                            trackingTags,
                            updatedOldKeywordStrings: keywordsInTracking,
                        }),
                    );

                    yield put(closeAddKeywordsPanel());
                    yield put(resetAddKwTagsDropdown());

                    yield put(
                        showAddedKeywordsMessage({
                            keywords: keywordsInLimit,
                            postponedProcessing,
                            estimatedTimeProcessing,
                        }),
                    );
                } else {
                    // Close previous message
                    yield put(closeAddedKeywordsMessage());

                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorAddKeywordsToTrackingAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(addKeywordsToTracking, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorAddKeywordsToTrackingAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorAddKeywordsToTrackingAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorAddKeywordsToTrackingAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.UNPROCESSABLE_ENTITY: {
                            // Not enough remaining tracked keywords
                            yield put(errorAddKeywordsToTrackingAction(payload));
                            yield put(showPricingMessage());
                            yield call(logError, 'AddKeywordsToTrackingDataSaga', payload);
                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorAddKeywordsToTrackingAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'AddKeywordsToTrackingDataSaga', payload);
                            } else {
                                yield call(addKeywordsToTracking, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorAddKeywordsToTrackingAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.add_keywords_error }));
                                yield call(logError, 'AddKeywordsToTrackingDataSaga', payload);
                            } else {
                                yield call(addKeywordsToTracking, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorAddKeywordsToTrackingAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.add_keywords_error }));
                yield call(logError, 'AddKeywordsToTrackingDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorAddKeywordsToTrackingAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.add_keywords_error }));
    },
);

const fetchReports = handleUncaught(
    function* fetchReports(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(trackingDetailIdSelector);

        if (!isNil(trackingId) && !isNil(accessToken)) {
            yield put(fetchingReportsAction());

            const { result, _timeout } = yield race({
                result: call(ReportSource.getData, { accessToken, trackingId }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedReportsAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorReportsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchReports, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorReportsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorReportsAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorReportsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorReportsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchReportsDataSaga', payload);
                            } else {
                                yield call(fetchReports, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorReportsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_reports }));
                                yield call(logError, 'FetchReportsDataSaga', payload);
                            } else {
                                yield call(fetchReports, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingDetailAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchReportsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorTrackingDetailAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const createNewReport = handleUncaught(
    function* createNewReport(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const reports = yield select(reportsDataSelector);
        const currentTrackingId = yield select(trackingDetailIdSelector);
        const newTrackingId = yield select(newTrackingCreatedTrackingIdSelector);
        const isNewTrackingRoute = yield select(isNewTrackingRouteSelector);

        const trackingId = isNewTrackingRoute ? newTrackingId : currentTrackingId;

        const alertTriggers = yield select(reportsPanelNewAlertTriggersSelector);
        const emails = yield select(reportsPanelNewEmailsSelector);
        const type = yield select(reportsPanelNewTypeSelector);
        let name = yield select(reportsPanelNewNameSelector);

        if (isNil(name)) {
            name = yield select(reportsPanelNewGeneratedNameSelector);
        }

        const options = { alertTriggers, emails, type, name };

        if (!isNil(accessToken)) {
            yield put(fetchingNewReportAction());

            const { result, _timeout } = yield race({
                result: call(ReportSource.create, { accessToken, trackingId }, options),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const newReports = concat([payload], reports);
                    yield put(receivedNewReportAction(trackingId, newReports));

                    // Show notification
                    yield call(showInfoNotification, `Report <strong>${name}</strong> was successfully created.`, {
                        html: true,
                    });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorNewReportAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(createNewReport, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorNewReportAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorNewReportAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorNewReportAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorNewReportAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'CreateNewReportSaga', payload);
                            } else {
                                yield call(createNewReport, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorNewReportAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.create_report_error }),
                                );
                                yield call(logError, 'CreateNewReportSaga', payload);
                            } else {
                                yield call(createNewReport, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorNewReportAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.create_report_error }));
                yield call(logError, 'CreateNewReportSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorNewReportAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.create_report_error }));
    },
);

const updateReport = handleUncaught(
    function* updateReport(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(trackingDetailIdSelector);
        const reportId = yield select(reportsPanelCurrentReportIdSelector);

        const alertTriggers = yield select(reportsPanelEditAlertTriggersSelector);
        const emails = yield select(reportsPanelEditEmailsSelector);
        const type = yield select(reportsPanelEditTypeSelector);
        const name = yield select(reportsPanelEditNameSelector);

        const options = { alertTriggers, emails, type, name };

        if (!isNil(accessToken)) {
            yield put(fetchingUpdateReportAction());

            const { result, _timeout } = yield race({
                result: call(ReportSource.update, { accessToken, trackingId, reportId }, options),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedUpdateReportAction({ alertTriggers, emails, id: reportId, name, type }));

                    // Show notification
                    yield call(showInfoNotification, `Report <strong>${name}</strong> was successfully updated.`, {
                        html: true,
                    });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorUpdateReportAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(updateReport, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorUpdateReportAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorUpdateReportAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorUpdateReportAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorUpdateReportAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'UpdateReportSaga', payload);
                            } else {
                                yield call(updateReport, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorUpdateReportAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.update_report_error }),
                                );
                                yield call(logError, 'UpdateReportSaga', payload);
                            } else {
                                yield call(updateReport, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorUpdateReportAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.update_report_error }));
                yield call(logError, 'UpdateReportSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorUpdateReportAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.update_report_error }));
    },
);

const deleteReport = handleUncaught(
    function* deleteReport(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const reports = yield select(reportsDataSelector);
        const reportId = yield select(reportsPanelCurrentReportIdSelector);
        const reportName = yield select(currentReportNameSelector);
        const trackingId = yield select(trackingDetailIdSelector);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(
                showDeleteConfirmationMessage({
                    resourceName: reportName,
                    resourceType: DeleteResourceTypes.REPORT,
                }),
            );

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(fetchingDeleteReportAction());

            const { result, _timeout } = yield race({
                result: call(ReportSource.delete, { accessToken, trackingId, reportId }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const newReports = reports.filter(report => report.id !== reportId);
                    yield put(receivedDeleteReportAction(trackingId, newReports));

                    // Show notification
                    yield call(
                        showInfoNotification,
                        `Report <strong>${reportName}</strong> was successfully deleted.`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorDeleteReportAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteReport, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorDeleteReportAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorDeleteReportAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorDeleteReportAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorDeleteReportAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteReportSaga', payload);
                            } else {
                                yield call(deleteReport, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorDeleteReportAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.delete_report_error }),
                                );
                                yield call(logError, 'DeleteReportSaga', payload);
                            } else {
                                yield call(deleteReport, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorDeleteReportAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.delete_report_error }));
                yield call(logError, 'DeleteReportSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledReportDeleteAction(reportId));
        }
    },
    function* onError() {
        yield put(errorDeleteReportAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_report_error }));
    },
);

const fetchTrackingTags = handleUncaught(
    function* fetchTrackingTags(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(TrackingTagSource.getData, { accessToken }, { trackingId }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedTrackingTagsAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorTrackingTagsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchTrackingTags, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorTrackingTagsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorTrackingTagsAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorTrackingTagsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorTrackingTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchTrackingTagsSaga', payload);
                            } else {
                                yield call(fetchTrackingTags, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorTrackingTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_tags }));
                                yield call(logError, 'FetchTrackingTagsSaga', payload);
                            } else {
                                yield call(fetchTrackingTags, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorTrackingTagsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'FetchTrackingTagsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorTrackingTagsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const bulkAssignTags = handleUncaught(
    function* bulkAssignTags(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const selectedKeywordIds = yield select(trackingFilteredSelectedKeywordIdsSelector);
        const selectedTagIds = yield select(tagsBulkDropdownSelectedIdsSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(
                    TrackingTagSource.assign,
                    { accessToken },
                    { trackingId, selectedTagIds, selectedKeywordIds },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const trackingTags = yield select(allTrackingDetailTagsWithAssignedKwCountSortedSelector);

                    yield put(
                        receivedBulkAssignSelectedTagsAction({
                            trackingTags,
                            tagIds: selectedTagIds,
                            keywordIds: selectedKeywordIds,
                        }),
                    );

                    // Show notification
                    yield call(showInfoNotification, 'Your keyword tags were successfully updated.');
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorBulkAssignSelectedTagsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(bulkAssignTags, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorBulkAssignSelectedTagsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorBulkAssignSelectedTagsAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorBulkAssignSelectedTagsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorBulkAssignSelectedTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'BulkAssignTagsSaga', payload);
                            } else {
                                yield call(bulkAssignTags, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorBulkAssignSelectedTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.assign_tags }));
                                yield call(logError, 'BulkAssignTagsSaga', payload);
                            } else {
                                yield call(bulkAssignTags, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorBulkAssignSelectedTagsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'BulkAssignTagsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorBulkAssignSelectedTagsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const bulkUnassignTags = handleUncaught(
    function* bulkUnassignTags(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const selectedKeywordIds = yield select(trackingFilteredSelectedKeywordIdsSelector);
        const selectedTagIds = yield select(tagsBulkDropdownSelectedIdsSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(
                    TrackingTagSource.unassign,
                    { accessToken },
                    { trackingId, selectedTagIds, selectedKeywordIds },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(
                        receivedBulkUnassignSelectedTagsAction({
                            tagIds: selectedTagIds,
                            keywordIds: selectedKeywordIds,
                        }),
                    );
                    yield put(updateTagFilter(selectedTagIds));

                    // Show notification
                    yield call(showInfoNotification, 'Your keyword tags were successfully updated.', { html: true });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorBulkUnassignSelectedTagsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(bulkUnassignTags, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorBulkUnassignSelectedTagsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorBulkUnassignSelectedTagsAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorBulkUnassignSelectedTagsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorBulkUnassignSelectedTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'BulkUnassignTagsSaga', payload);
                            } else {
                                yield call(bulkUnassignTags, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorBulkUnassignSelectedTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.unassign_tags }));
                                yield call(logError, 'BulkUnassignTagsSaga', payload);
                            } else {
                                yield call(bulkUnassignTags, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorBulkUnassignSelectedTagsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'BulkUnassignTagsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorBulkUnassignSelectedTagsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const assignTagsToCurrentKeyword = handleUncaught(
    function* assignTagsToCurrentKeyword(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const selectedTagIds = yield select(tagsDropdownSelectedIdsSelector);
        const trackingId = yield select(currentTrackingIdSelector);
        const keywordId = yield select(trackingCurrentKeywordDetailSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(
                    TrackingTagSource.assign,
                    { accessToken },
                    {
                        trackingId,
                        selectedTagIds,
                        selectedKeywordIds: [keywordId],
                    },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    const trackingTags = yield select(allTrackingDetailTagsWithAssignedKwCountSortedSelector);

                    yield put(
                        receivedAssignSelectedTagsAction({
                            keywordId,
                            tagIds: selectedTagIds,
                            trackingTags,
                        }),
                    );

                    const tagCount = selectedTagIds.length;

                    // Show notification
                    yield call(
                        showInfoNotification,
                        `${tagCount} ${tagCount > 1 ? 'tags were' : 'tag was'} successfully assigned.`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorBulkAssignSelectedTagsAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(assignTagsToCurrentKeyword, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorBulkAssignSelectedTagsAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorBulkAssignSelectedTagsAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorBulkAssignSelectedTagsAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorBulkAssignSelectedTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'AssignTagsToCurrentKeywordSaga', payload);
                            } else {
                                yield call(assignTagsToCurrentKeyword, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorBulkAssignSelectedTagsAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.assign_tags }));
                                yield call(logError, 'AssignTagsToCurrentKeywordSaga', payload);
                            } else {
                                yield call(assignTagsToCurrentKeyword, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorAssignSelectedTagsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'AssignTagsToCurrentKeywordSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorAssignSelectedTagsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const unassignTagFromCurrentKeyword = handleUncaught(
    function* unassignTagFromCurrentKeyword(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const { tagId, tagName } = action.payload;
        const trackingId = yield select(currentTrackingIdSelector);
        const keywordId = yield select(trackingCurrentKeywordDetailSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingTrackingTagsAction());
            yield put(fetchingUnassignTagAction(tagId));

            const { result, _timeout } = yield race({
                result: call(
                    TrackingTagSource.unassign,
                    { accessToken },
                    {
                        trackingId,
                        selectedTagIds: [tagId],
                        selectedKeywordIds: [keywordId],
                    },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedUnassignTagAction({ tagId, keywordId }));
                    yield put(updateTagFilter([tagId]));

                    // Show notification
                    yield call(showInfoNotification, `Tag <strong>${tagName}</strong> was successfully removed.`, {
                        html: true,
                    });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorUnassignTagAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(unassignTagFromCurrentKeyword, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorUnassignTagAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorUnassignTagAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorUnassignTagAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorUnassignTagAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'UnassignTagFromCurrentKeywordSaga', payload);
                            } else {
                                yield call(unassignTagFromCurrentKeyword, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorUnassignTagAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.unassign_tags }));
                                yield call(logError, 'UnassignTagFromCurrentKeywordSaga', payload);
                            } else {
                                yield call(unassignTagFromCurrentKeyword, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorUnassignTagAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'UnassignTagFromCurrentKeywordSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorUnassignTagAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const createTrackingTag = handleUncaught(
    function* createTrackingTag(action, retrying = false) {
        const { name, color, createdFrom } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(TrackingTagSource.create, { accessToken }, { trackingId, name, color }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedCreateTagAction({ createdFrom, tag: payload }));

                    // Show notification
                    // yield call(
                    //     showInfoNotification,
                    //     `Tag <strong>${name}</strong> was successfully created.`,
                    //     { html: true }
                    // );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorCreateTagAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(createTrackingTag, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorCreateTagAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorCreateTagAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorCreateTagAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorCreateTagAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'CreateTagSaga', payload);
                            } else {
                                yield call(createTrackingTag, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorCreateTagAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.create_tag }));
                                yield call(logError, 'CreateTagSaga', payload);
                            } else {
                                yield call(createTrackingTag, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorCreateTagAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'CreateTagSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorCreateTagAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const updateTrackingTagName = handleUncaught(
    function* updateTrackingTagName(action, retrying = false) {
        const { tagId, tagName } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            // yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(TrackingTagSource.update, { accessToken }, { trackingId, tagId, name: tagName }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedUpdateTagNameAction({ tagId, tagName }));

                    // Show notification
                    yield call(showInfoNotification, `Tag was successfully renamed to <strong>${tagName}</strong>.`, {
                        html: true,
                    });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorUpdateTagNameAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(updateTrackingTagName, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorUpdateTagNameAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorUpdateTagNameAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorUpdateTagNameAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorUpdateTagNameAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'UpdateTrackingTagNameSaga', payload);
                            } else {
                                yield call(updateTrackingTagName, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorUpdateTagNameAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.update_tag_name }));
                                yield call(logError, 'UpdateTrackingTagNameSaga', payload);
                            } else {
                                yield call(updateTrackingTagName, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorUpdateTagNameAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'UpdateTrackingTagNameSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorUpdateTagNameAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const updateTrackingTagColor = handleUncaught(
    function* updateTrackingTagColor(action, retrying = false) {
        const { tagId, tagColor } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            // yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(TrackingTagSource.update, { accessToken }, { trackingId, tagId, color: tagColor }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedUpdateTagColorAction({ tagId, tagColor }));

                    // Show notification
                    yield call(
                        showInfoNotification,
                        `Tag color successfully changed to <strong>${tagColor}</strong>.`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorUpdateTagColorAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(updateTrackingTagColor, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorUpdateTagColorAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorUpdateTagColorAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorUpdateTagColorAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorUpdateTagColorAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'UpdateTrackingTagColorSaga', payload);
                            } else {
                                yield call(updateTrackingTagColor, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorUpdateTagColorAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.update_tag_color }));
                                yield call(logError, 'UpdateTrackingTagColorSaga', payload);
                            } else {
                                yield call(updateTrackingTagColor, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorUpdateTagColorAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'UpdateTrackingTagColorSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorUpdateTagColorAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const deleteTrackingTag = handleUncaught(
    function* deleteTrackingTag(action, retrying = false) {
        const { tagId, tagName } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);
        const { tagIds: filteringTagIds } = yield select(trackingQuickFilterSettingsSelector);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(
                showDeleteConfirmationMessage({
                    resourceName: tagName,
                    resourceType: DeleteResourceTypes.TAG,
                }),
            );

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(fetchingTrackingTagsAction());

            const { result, _timeout } = yield race({
                result: call(TrackingTagSource.delete, { accessToken }, { trackingId, tagId }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedDeleteTagAction(tagId));

                    if (includes(tagId, filteringTagIds)) {
                        const newTagIds = filteringTagIds.filter(currentTagId => currentTagId !== tagId);

                        yield put(setTrackingQuickFilterTagIds(newTagIds));
                    }

                    // Show notification
                    yield call(showInfoNotification, `Tag <strong>${tagName}</strong> was successfully deleted.`, {
                        html: true,
                    });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorDeleteTagAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteTrackingTag, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorDeleteTagAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorDeleteTagAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorDeleteTagAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorDeleteTagAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteTagSaga', payload);
                            } else {
                                yield call(deleteTrackingTag, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorDeleteTagAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.delete_tag }));
                                yield call(logError, 'DeleteTagSaga', payload);
                            } else {
                                yield call(deleteTrackingTag, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorDeleteTagAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'DeleteTagSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorDeleteTagAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const deleteTrackedKeyword = handleUncaught(
    function* deleteTrackedKeyword(action, retrying = false) {
        const { keywordId, keyword } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        // Confirmation message helper variables
        let _cancel = null; // eslint-disable-line no-underscore-dangle
        let _closeAll = null; // eslint-disable-line no-underscore-dangle
        let confirm = null;

        if (retrying === false) {
            // Show confirmation message and only proceed if is confirmed
            yield put(
                showDeleteConfirmationMessage({
                    resourceName: keyword,
                    resourceType: DeleteResourceTypes.TRACKED_KEYWORD,
                }),
            );

            ({ _cancel, _closeAll, confirm } = yield race({
                _cancel: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CLOSE),
                _closeAll: take(ActionTypes.UI_ALL_CLOSE),
                confirm: take(ActionTypes.UI_MESSAGES_DELETE_CONFIRMATION_CONFIRM),
            }));
        }

        if ((!isNil(confirm) || retrying === true) && !isNil(accessToken)) {
            yield put(fetchingDeleteKeywordAction());

            const { result, _timeout } = yield race({
                result: call(TrackingKeywordsSource.delete, accessToken, { trackingId, keywordIds: [keywordId] }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    // Let reducers remove selected kw ids
                    yield put(receivedDeleteKeywordAction({ keywordId, trackingId }));

                    // Show notification
                    yield call(
                        showInfoNotification,
                        `Keyword <strong translate="no">${keyword}</strong> was successfully removed.`,
                        { html: true },
                    );
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorDeleteKeywordAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteTrackedKeyword, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorDeleteKeywordAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorDeleteKeywordAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorDeleteKeywordAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorDeleteKeywordAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteSelectedTrackedKeywordsSaga', payload);
                            } else {
                                yield call(deleteTrackedKeyword, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorDeleteKeywordAction(payload));
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.delete_tracking_keyword_error,
                                    }),
                                );
                                yield call(logError, 'DeleteSelectedTrackedKeywordsSaga', payload);
                            } else {
                                yield call(deleteTrackedKeyword, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorDeleteKeywordAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(
                    showFailureMessage({
                        details: Strings.messages.failure.delete_selected_tracking_keywords_error,
                    }),
                );
                yield call(logError, 'DeleteSelectedTrackedKeywordsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(cancelledDeleteKeywordAction({ keywordId, trackingId }));
        }
    },
    function* onError() {
        yield put(errorTrackingsDeleteAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_selected_tracking_keywords_error }));
    },
);

const exportSelectedKeywords = handleUncaught(
    function* exportSelectedKeywords() {
        yield put(fetchingSelectedTrackingKeywordsExportAction());

        const trackingDomain = yield select(currentTrackingDomainSelector);
        const selectedKeywords = yield select(trackingFilteredSelectedKeywordsSelector);

        const currentTimeframe = yield select(trackingTimeframeSelector);
        const currentTimeframeISO = TimeframeService.translateToISO(currentTimeframe);

        const timeframeString = `${currentTimeframeISO.from}--${currentTimeframeISO.to}`;
        const fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(trackingDomain)}_${timeframeString}`;
        const timeframePointsObject = yield select(trackingDetailTimeframePointsObjectSelector);

        const csv = yield call(KeywordExportService.export, {
            keywords: selectedKeywords,
            timeframePointsObject,
        });

        const success = yield call(DownloaderService.downloadCSV, fileName, csv);

        if (success) {
            yield put(receivedSelectedTrackingKeywordsExportAction());
            yield call(showInfoNotification, 'Keywords were successfully exported.');
        } else {
            yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
            yield put(errorSelectedTrackingKeywordsExportAction());
            yield call(logError, 'ExportSelectedKeywordsSaga|DownloaderService', {});
        }
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
        yield put(errorSelectedTrackingKeywordsExportAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const fetchAnnouncements = handleUncaught(
    function* fetchAnnouncements(retrying = false) {
        yield put(fetchingAnnouncementsAction());

        const { result, _timeout } = yield race({
            result: call(AnnouncementsSource.getData),
            _timeout: delay(MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedAnnouncementsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorAnnouncementsAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorAnnouncementsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.fetch_announcements_error,
                                }),
                            );
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorAnnouncementsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchAnnouncementsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorAnnouncementsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const createTrackingAnnotation = handleUncaught(
    function* createTrackingAnnotation(action, retrying = false) {
        const { annotationText, date, nonShareable } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingCreateAnnotationAction());

            const { result, _timeout } = yield race({
                result: call(
                    AnnotationSource.create,
                    { accessToken, trackingId },
                    { annotationText, date, nonShareable },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedCreateAnnotationAction(payload));

                    yield call(showInfoNotification, 'Annotation was successfully added.', { html: false });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorCreateAnnotationAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(createTrackingAnnotation, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorCreateAnnotationAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorCreateAnnotationAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorCreateAnnotationAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorCreateAnnotationAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'CreateAnnotationSaga', payload);
                            } else {
                                yield call(createTrackingAnnotation, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorCreateAnnotationAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.create_annotation }));
                                yield call(logError, 'CreateAnnotationSaga', payload);
                            } else {
                                yield call(createTrackingAnnotation, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorCreateAnnotationAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'CreateAnnotationSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorCreateAnnotationAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const deleteTrackingAnnotation = handleUncaught(
    function* deleteTrackingAnnotation(action, retrying = false) {
        const annotationId = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            yield put(setTrackingAnnotationDeletingId(annotationId));

            const { result, _timeout } = yield race({
                result: call(AnnotationSource.delete, { accessToken, trackingId, annotationId }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedDeleteAnnotationAction(annotationId));

                    // Show notification
                    yield call(showInfoNotification, 'The annotation was successfully deleted.', { html: false });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorDeleteAnnotationAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(deleteTrackingAnnotation, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorDeleteAnnotationAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorDeleteAnnotationAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorDeleteAnnotationAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorDeleteAnnotationAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'DeleteAnnotationSaga', payload);
                            } else {
                                yield call(deleteTrackingAnnotation, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorDeleteAnnotationAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.delete_annotation }));
                                yield call(logError, 'DeleteAnnotationSaga', payload);
                            } else {
                                yield call(deleteTrackingAnnotation, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorDeleteAnnotationAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'DeleteAnnotationSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorDeleteAnnotationAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const updateTrackingAnnotation = handleUncaught(
    function* updateTrackingAnnotation(action, retrying = false) {
        const { annotationId, annotationText, nonShareable } = action.payload;
        const accessToken = yield select(accessTokenSelector);
        const trackingId = yield select(currentTrackingIdSelector);

        if (!isNil(accessToken)) {
            yield put(setTrackingAnnotationUpdatingId(annotationId));

            const { result, _timeout } = yield race({
                result: call(
                    AnnotationSource.update,
                    { accessToken, trackingId, annotationId },
                    { annotationText, nonShareable },
                ),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedUpdateAnnotationAction({ annotationId, annotationText, nonShareable }));

                    // Show notification
                    yield call(showInfoNotification, 'Annotation was successfully updated.', { html: false });
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorUpdateAnnotationAction(payload));
                                yield put(showNoConnectionMessage());
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(updateTrackingAnnotation, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorUpdateAnnotationAction(payload));
                            yield put(showAccessDeniedMessage());
                            break;
                        }
                        case ErrorCodes.UNAUTHORIZED: {
                            yield put(errorUpdateAnnotationAction(payload));
                            yield put(showUnauthorizedMessage());
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorUpdateAnnotationAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorUpdateAnnotationAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'UpdateTrackingAnnotation', payload);
                            } else {
                                yield call(updateTrackingAnnotation, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorUpdateAnnotationAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.update_annotation }));
                                yield call(logError, 'UpdateTrackingAnnotation', payload);
                            } else {
                                yield call(updateTrackingAnnotation, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorUpdateAnnotationAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'UpdateTrackingAnnotation', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorUpdateAnnotationAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const checkUnleashSession = handleUncaught(function* checkUnleashSession(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(UnleashSource.get),
        _timeout: delay(MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            yield put(setUnleashSessionAction({ unleashSession: payload.sessionId }));
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchUnleashSessionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

const checkNewAppVersion = handleUncaught(function* checkNewAppVersion(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(VersionSource.get),
        _timeout: delay(MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            let newAppVersion;
            const currentAppVersion = config.APP_VERSION;

            if (config.production()) {
                newAppVersion = payload.serpwatcher;
            } else {
                newAppVersion = payload.serpwatcherBeta;
            }

            if (!isNil(newAppVersion)) {
                const newAppVersionParts = newAppVersion.split('.');
                const newAppVersionMajor = parseInt(newAppVersionParts[0], 10);
                // console.log('newAppVersionMajor', newAppVersionMajor);
                const newAppVersionMinor = parseInt(newAppVersionParts[1], 10);
                // console.log('newAppVersionMinor', newAppVersionMinor);

                const currentAppVersionParts = currentAppVersion.split('.');
                const currentAppVersionMajor = parseInt(currentAppVersionParts[0], 10);
                // console.log('currentAppVersionMajor', currentAppVersionMajor);
                const currentAppVersionMinor = parseInt(currentAppVersionParts[1], 10);
                // console.log('currentAppVersionMinor', currentAppVersionMinor);

                if (newAppVersionMajor > currentAppVersionMajor || newAppVersionMinor > currentAppVersionMinor) {
                    const notificationShown = yield select(newVersionNotificationShownSelector);

                    // Only show if not already shown during this session
                    if (notificationShown === false) {
                        // We have an older version of application.
                        // Show a special notification.
                        yield call(
                            showInfoNotification,
                            `
                                <h4 class="font-14">
                                    UPDATE AVAILABLE 🤩
                                </h4>

                                <p>
                                    Please, reload the application to get newest features and prevent possible glitches.
                                </p>

                                <br />

                                <button
                                    class="mg-btn is-xsmall is-orange is-gradient mg-margin-t-5"
                                    onclick="location.reload();"
                                    type="button"
                                >
                                    Reload now
                                </button>
                            `,
                            {
                                html: true,
                                timeout: 'none',
                            },
                        );

                        yield put(setNewVersionNotificationShown());
                    }
                }
            }
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchAppVersionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

const fetchSnapshotImage = handleUncaught(
    function* fetchSnapshotImage(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const { serpSnapshotId, keyword } = yield select(trackingKeywordDetailDataSelector);

        const { result, _timeout } = yield race({
            result: call(TrackingKeywordsSource.getSnapshot, { accessToken, serpSnapshotId }),
            _timeout: delay(MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;
            if (!error) {
                const fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(keyword)}`;

                try {
                    DownloaderService.downloadBase64Image({ base64Data: payload.image, fileName });
                    yield put(exportingSnapshotImageFinishedAction());

                    yield call(showInfoNotification, 'Keyword SERP has been successfully exported to image', {
                        html: false,
                    });
                } catch (e) {
                    yield put(errorSnapshotImageAction());
                    yield put(showFailureMessage({ details: Strings.messages.failure.download_snapshot }));
                    yield call(logError, 'FetchSnapshotImageSaga', payload);
                }
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorSnapshotImageAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchSnapshotImage, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorSnapshotImageAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.UNAUTHORIZED: {
                        yield put(errorSnapshotImageAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorSnapshotImageAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorSnapshotImageAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchSnapshotSaga', payload);
                        } else {
                            yield call(fetchSnapshotImage, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorSnapshotImageAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.download_snapshot }));
                            yield call(logError, 'FetchSnapshotImageSaga', payload);
                        } else {
                            yield call(fetchSnapshotImage, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorSnapshotImageAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchSnapshotImageSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorSnapshotImageAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

function newAppVersionCheckIntervalChannel() {
    return eventChannel(emitter => {
        const intervalId = setInterval(() => {
            emitter({
                intervalId,
            });
        }, Defaults.APP_VERSION_CHECK_INTERVAL);
        // }, 10 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    });
}

export function* fetchAfterLoginData() {
    yield spawn(fetchAnnouncements);
    yield spawn(checkNewAppVersion);
    yield spawn(checkUnleashSession);
}

const updateTrackingDomain = handleUncaught(
    function* updateTrackingDomain(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);
        const tracking = action.payload;

        if (!isNil(accessToken)) {
            if (retrying === false) {
                yield put(optimisticUpdateTrackingDomainAction(tracking));

                yield fork(showInfoNotification, `Tracking details have been sucessfuly changed.`, {
                    html: true,
                });
            }

            const { result, _timeout } = yield race({
                result: call(TrackingSource.updateDomain, accessToken, tracking),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedTrackingDomainUpdateAction(payload.tracking));
                    yield put(receivedCreateAnnotationAction(payload.annotation));
                } else {
                    const handler = handleError(
                        action,
                        payload,
                        'UpdateTrackingDomainSaga',
                        retrying,
                        errorUpdateTrackingDomainAction,
                        revertOptimisticUpdateTrackingDomainAction,
                        updateTrackingDomain,
                        Strings.messages.failure.update_tracking_domain,
                    );

                    yield call(handler);
                }
            } else {
                yield put(errorUpdateTrackingDomainAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'UpdateTrackingDomainSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorUpdateTrackingDomainAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const updateTrackingShareToken = handleUncaught(
    function* updateTrackingShareToken(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            const tracking = yield select(trackingDetailDataSelector);
            const { result, _timeout } = yield race({
                result: call(TrackingSource.updateShareToken, accessToken, { id: tracking.id }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload: data } = result;

                if (!error) {
                    yield put(receivedTrackingShareTokenUpdateAction(data.shareToken));
                    yield fork(
                        showInfoNotification,
                        // eslint-disable-next-line max-len
                        `New share url for <strong translate="no">${tracking.domain}</strong> has been successfully generated`,
                        { html: true },
                    );
                } else {
                    const handler = handleError(
                        action,
                        data,
                        'UpdateTrackingShareToken',
                        retrying,
                        errorTrackingShareTokenUpdateAction,
                        null,
                        updateTrackingShareToken,
                        Strings.messages.failure.update_tracking_share_token,
                    );

                    yield call(handler);
                }
            } else {
                yield put(errorTrackingShareTokenUpdateAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield call(logError, 'UpdateTrackingDomainShareTokenSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorTrackingShareTokenUpdateAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

/**
 *
 * WATCHERS
 *
 */

function* watchNewAppVersionByInterval() {
    const channel = yield call(newAppVersionCheckIntervalChannel);
    yield takeEvery(channel, checkNewAppVersion);
}

function* watchLocationsRequests() {
    yield takeLatest(ActionTypes.DATA_LOCATIONS_REQUESTED, fetchLocations);
}

function* watchListRequests() {
    yield takeLatest(ActionTypes.DATA_LISTS_REQUESTED, fetchListData);
}

function* watchNewTrackingListKeywordsRequests() {
    yield takeLatest(ActionTypes.DATA_NEW_TRACKING_LISTS_KEYWORDS_REQUESTED, fetchNewTrackingListKeywordsData);
}

function* watchAddKeywordsPanelListKeywordsRequests() {
    yield takeLatest(
        ActionTypes.DATA_LISTS_PANELS_ADD_KEYWORDS_KEYWORDS_REQUESTED,
        fetchAddKeywordsPanelListKeywordsData,
    );
}

function* watchNewTrackingRequests() {
    yield takeLatest(ActionTypes.DATA_NEW_TRACKING_REQUESTED, createNewTracking);
}

function* watchNewTrackingFillRequests() {
    yield takeLatest(ActionTypes.DATA_NEW_TRACKING_FILL_REQUESTED, fillNewTracking);
}

function* watchNewTrackingCloneRequests() {
    yield takeLatest(ActionTypes.DATA_NEW_TRACKING_CLONE_REQUESTED, cloneAndCreateNewTracking);
}

function* watchDeleteSelectedTrackingKeywordsRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_REQUESTED, deleteSelectedTrackedKeywords);
}

function* watchRestoreSelectedTrackingKeywordsRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_REQUESTED, restoreSelectedTrackedKeywords);
}

function* watchRestoreSelectedTrackingKeywordsFinished() {
    yield takeLatest(
        [
            ActionTypes.DATA_TRACKING_KEYWORDS_RESTORE_SELECTED_RECEIVED,
            ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_SELECTED_RECEIVED,
            ActionTypes.UI_SETTINGS_IS_WITH_DELETED_KEYWORDS,
        ],
        refetchTrackingDetail,
    );
}

function* watchAddKeywordsToTrackinRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_ADD_KEYWORDS_REQUESTED, addKeywordsToTracking);
}

function* watchTrackingDetailRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_DETAIL_REQUESTED, fetchTrackingDetail);
}

function* watchTrackingStatsDetailRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_STATS_DETAIL_REQUESTED, fetchTrackingStatsDetail);
}

function* debounceTrackingDetailFilterRequest() {
    yield put(fetchingTrackingStatsDetailAction());
    yield delay(TRACKING_DETAIL_FILTER_SEARCH_DEBOUNCE_MS);
    yield call(reactToTrackingDetailFilters);
}

function* watchTrackingDetailFilterSearchRequests() {
    yield takeLatest(ActionTypes.UI_TRACKING_QUICK_FILTER_SETTINGS_SEARCH_SET, debounceTrackingDetailFilterRequest);
}

function* watchTrackingDetailFilterSettingsRequests() {
    yield takeLatest(
        [
            ActionTypes.UI_TRACKING_QUICK_FILTER_SETTINGS_TAG_IDS_SET,
            ActionTypes.UI_TRACKING_FILTER_ACTIVE_SET,
            ActionTypes.UI_TRACKING_FILTER_SETTINGS_SET,
            ActionTypes.UI_TRACKING_FILTER_RESET,
        ],
        reactToTrackingDetailFilters,
    );
}

function* watchTrackingDetailReloadRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_DETAIL_RELOAD_REQUESTED, refetchTrackingDetail);
}

function* watchTrackingKeywordDetailRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_KEYWORD_DETAIL_REQUESTED, fetchTrackingKeywordDetail);
}

function* watchTrackingsRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKINGS_REQUESTED, fetchTrackingsData);
}

function* watchIsWithDeletedTrackings() {
    yield takeLatest(ActionTypes.UI_SETTINGS_IS_WITH_DELETED_TRACKINGS, fetchTrackingsData);
}

function* watchRestoreTracking() {
    yield takeLatest(ActionTypes.DATA_TRACKINGS_RESTORE_RECEIVED, fetchTrackingsData);
}

function* watchReportsRequests() {
    yield takeLatest(ActionTypes.DATA_REPORTS_REQUESTED, fetchReports);
}

function* watchReportsNewRequests() {
    yield takeLatest(ActionTypes.DATA_NEW_REPORT_REQUESTED, createNewReport);
}

function* watchReportsUpdateRequests() {
    yield takeLatest(ActionTypes.DATA_UPDATE_REPORT_REQUESTED, updateReport);
}

function* watchReportsDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_DELETE_REPORT_REQUESTED, deleteReport);
}

function* watchTrackingTagsRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_REQUESTED, fetchTrackingTags);
}

function* watchTagsBulkAssignRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_BULK_ASSIGN_REQUESTED, bulkAssignTags);
}

function* watchTagsBulkUnassignRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_BULK_UNASSIGN_REQUESTED, bulkUnassignTags);
}

function* watchTagsAssignRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_ASSIGN_REQUESTED, assignTagsToCurrentKeyword);
}

function* watchTagsUnassignRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_UNASSIGN_REQUESTED, unassignTagFromCurrentKeyword);
}

function* watchTagsCreateRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_CREATE_REQUESTED, createTrackingTag);
}

function* watchTagsUpdateNameRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_UPDATE_NAME_REQUESTED, updateTrackingTagName);
}

function* watchTagsUpdateColorRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_UPDATE_COLOR_REQUESTED, updateTrackingTagColor);
}

function* watchTagsDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_TAGS_DELETE_REQUESTED, deleteTrackingTag);
}

function* watchAnnotationCreateRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_ANNOTATIONS_CREATE_REQUESTED, createTrackingAnnotation);
}

function* watchAnnotationUpdateRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_ANNOTATIONS_UPDATE_REQUESTED, updateTrackingAnnotation);
}

function* watchAnnotationDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_ANNOTATIONS_DELETE_REQUESTED, deleteTrackingAnnotation);
}

function* watchTrackingKeywordsDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_KEYWORDS_DELETE_REQUESTED, deleteTrackedKeyword);
}

function* watchExportSelectedKeywordsRequests() {
    yield takeLatest(ActionTypes.DATA_TRACKING_KEYWORDS_EXPORT_SELECTED_REQUESTED, exportSelectedKeywords);
}

function* watchSnapshotImageRequest() {
    yield takeLatest(ActionTypes.DATA_SNAPSHOT_IMAGE_REQUESTED, fetchSnapshotImage);
}

function* watchTrackingDomainUpdateRequest() {
    yield takeLatest(ActionTypes.DATA_TRACKING_DOMAIN_UPDATE_REQUESTED, updateTrackingDomain);
}

function* watchTrackingShareTokenRequest() {
    yield takeLatest(ActionTypes.DATA_TRACKING_SHARE_TOKEN_UPDATE_REQUESTED, updateTrackingShareToken);
}

export function* watchDataRequests() {
    yield spawn(watchLocationsRequests);
    yield spawn(watchRestoreSelectedTrackingKeywordsFinished);
    yield spawn(watchRestoreSelectedTrackingKeywordsRequests);
    yield spawn(watchTrackingsRequests);
    yield spawn(watchIsWithDeletedTrackings);
    yield spawn(watchListRequests);
    yield spawn(watchNewTrackingListKeywordsRequests);
    yield spawn(watchAddKeywordsPanelListKeywordsRequests);
    yield spawn(watchNewTrackingRequests);
    yield spawn(watchNewTrackingCloneRequests);
    yield spawn(watchNewTrackingFillRequests);
    yield spawn(watchTrackingDetailRequests);
    yield spawn(watchTrackingDetailReloadRequests);
    yield spawn(watchTrackingDetailFilterSettingsRequests);
    yield spawn(watchTrackingDetailFilterSearchRequests);
    yield spawn(watchTrackingStatsDetailRequests);
    yield spawn(watchTrackingKeywordDetailRequests);
    yield spawn(watchDeleteSelectedTrackingKeywordsRequests);
    yield spawn(watchAddKeywordsToTrackinRequests);
    yield spawn(watchReportsRequests);
    yield spawn(watchReportsNewRequests);
    yield spawn(watchReportsUpdateRequests);
    yield spawn(watchReportsDeleteRequests);
    yield spawn(watchTrackingTagsRequests);
    yield spawn(watchTagsBulkAssignRequests);
    yield spawn(watchTagsBulkUnassignRequests);
    yield spawn(watchTagsAssignRequests);
    yield spawn(watchTagsUnassignRequests);
    yield spawn(watchTagsCreateRequests);
    yield spawn(watchTagsUpdateNameRequests);
    yield spawn(watchTagsUpdateColorRequests);
    yield spawn(watchTagsDeleteRequests);
    yield spawn(watchTrackingKeywordsDeleteRequests);
    yield spawn(watchExportSelectedKeywordsRequests);
    yield spawn(watchAnnotationCreateRequests);
    yield spawn(watchAnnotationUpdateRequests);
    yield spawn(watchAnnotationDeleteRequests);
    yield spawn(watchSnapshotImageRequest);
    yield spawn(watchNewAppVersionByInterval);
    yield spawn(watchTrackingDomainUpdateRequest);
    yield spawn(watchTrackingShareTokenRequest);
    yield spawn(watchTrackingRequests);
    yield spawn(watchKwfinderListRequests);
    yield spawn(watchSuggestionsRequests);
    yield spawn(watchRestoreTracking);
}
