import { delay, call, spawn, put, race, select, takeLatest } from 'redux-saga/effects';
import {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/lib/constants/ErrorCodes';
import { isNil } from 'ramda';

import ActionTypes from 'constants/ActionTypes';
import Strings from 'constants/Strings';

import { handleUncaught, logError, handleError } from 'sagas/errorSagas';

import ListSource from 'sources/ListSource';

import { accessTokenSelector } from 'selectors/userSelectors';

import { errorFetchKwfinderListDetailAction, fetchingListsKeywordsAction } from 'actions/dataActions';
import {
    showFailureMessage,
    requestedAddKeywordsPanelKeywordsSet,
    showAddKeywordsPanel,
    requestedNewTrackingKeywordsSet,
} from 'actions/uiActions';

const MAX_REQUEST_TIMEOUT = 60 * 1000

const importKwfinderListData = handleUncaught(
    function* importKwfinderListData(action, retrying = false) {
        const { kwfinderListId, newTracking } = action.payload;
        const accessToken = yield select(accessTokenSelector);

        yield put(fetchingListsKeywordsAction());

        if (!newTracking) {
            yield put(showAddKeywordsPanel());
        }

        if (!isNil(kwfinderListId) && !isNil(accessToken)) {
            const { result, _timeout } = yield race({
                result: call(ListSource.getDetail, { accessToken, listId: kwfinderListId }),
                _timeout: delay(MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    if (newTracking) {
                        yield put(requestedNewTrackingKeywordsSet({ newKeywords: payload, isKwfImport: true }));
                    } else {
                        yield put(requestedAddKeywordsPanelKeywordsSet({ newKeywords: payload, isKwfImport: true }));
                    }
                } else {
                    const handler = handleError(
                        action,
                        payload,
                        'FetchKwfinderListDetail',
                        retrying,
                        errorFetchKwfinderListDetailAction,
                        null,
                        importKwfinderListData,
                        Strings.messages.failure.fetch_kwfinder_list_detail,
                    );

                    yield call(handler);
                }
            } else {
                yield put(errorFetchKwfinderListDetailAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.delete_tracking_error }));
                yield call(logError, 'FetchKwfinderListDetail', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        }
    },
    function* onError() {
        yield put(errorFetchKwfinderListDetailAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.delete_tracking_error }));
    },
);

function* watchImportKwfinderListData() {
    yield takeLatest(ActionTypes.DATA_KWFINDER_LIST_URL_IMPORT_REQUESTED, importKwfinderListData);
}

export function* watchKwfinderListRequests() {
    yield spawn(watchImportKwfinderListData);
}
